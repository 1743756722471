.range
  display inline-flex
  align-items center
  user-select none

  &_align
    &_horizontal
      min-width 120px
      height 28px
      flex-direction row

    &_vertical
      min-height 120px
      width 28px
      flex-direction column

    ~/_with-icon &_horizontal
      padding 0 5px

    ~/_with-icon &_vertical
      padding 5px 0

  &__body
    position relative

    ~/_align_horizontal &
      height 4px
      width 100%

    ~/_align_vertical &
      height 100%
      width 4px

    ~/_align_horizontal &_with-icon
      margin 0 5px

    ~/_align_vertical &_with-icon
      margin 5px 0

  &__line
    background linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF
    box-shadow inset 0px 1px 0px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)
    border-radius 8px

    ~/_align_horizontal &
      height 4px
      width 100%

    ~/_align_vertical &
      width 4px
      height 100%

  &__indicator
    border-radius 8px
    position absolute
    background-color rgba($accent_color, 0.5)

    ~/_align_horizontal &
      top 0
      height 4px

    ~/_align_vertical &
      left 0
      width 4px

  &__range-handle
    width 0
    height 0
    position absolute

    &::before
      content ''
      position absolute
      background #0099FF
      box-shadow 0px 5px 10px rgba(0, 153, 255, 0.3), inset 0px -1px 0px rgba(0, 0, 0, 0.1)
      border-radius 2px
      transform translate3d(-50%, -50%, 0)

    &::after
      content ''
      width 25px
      height 25px
      position absolute
      transform translate3d(-50%, -50%, 0)

    ~/_align_horizontal &
      top 50%
      cursor col-resize

      &::before
        width 10px
        height 16px

    ~/_align_vertical &
      left 50%
      cursor row-resize

      &::before
        width 16px
        height 10px

  &__icon
    width 28px
    height 28px
    display flex
    align-items center
    justify-content center
