.root
  height 100%
  box-sizing border-box
  background-color #f0f0f0
  font-family Roboto

.tab-panel
  display flex
  padding 12px 16px
  background-color #fff
  justify-content space-between
  &_newUI
    padding 8px

.grid
  flex 1

.grid__item
  padding 10px
  justify-content space-between
  box-sizing border-box
  box-shadow -0.5px -0.5px 0 0.5px #ccc

.container
  padding 2em
  width 90%
  height 600px
  margin-left auto
  margin-right auto

.app-loader
  width 100%
  height 100%
  display flex
  align-items center
  justify-content center

.ant-picker-cell
  padding 3px 0 !important
  border none !important

.offscreen-lsf
  bottom 100%
  right 100%
  opacity 0
  position absolute
  width 100vw
  height 100vh
  background-color #fff
  z-index 100000

.offscreen-lsf.visible
  bottom auto
  right auto
  top 0
  left 0
  opacity 1

.fill-container
  flex 1
  display flex
  align-items center
  justify-content center

.horizontal-shadow
  &::after
    top 100%
    width 100%
    left 0
    content ""
    position absolute
    height 4px
    background linear-gradient(to bottom, rgba(0, 0, 0, 0.15), transparent)

.crash
  width 100vw
  height 100vh
  display flex
  align-items center
  justify-content center
  flex-direction column

  &__header
    font-size 22px
    font-weight 500

  &__description
    margin-top 32px
    font-size 16px
