.filter-line
  display grid
  min-height 24px
  max-width 550px
  min-width min-content
  padding 5px 10px
  box-sizing content-box
  align-items flex-start
  grid-gap 2px
  grid-template-columns 70px 110px 100px 120px 24px

  &__remove
    width 24px
    height 24px
    display flex
    align-items center
    justify-content center

  &__column
    min-height 24px

    &.conjunction
      display flex
      align-items center
      justify-content flex-end
      width 65px

    &.operation
      flex 0

    &.value
      flex 1
      display grid
      grid-auto-flow column

  &__group
    flex 0
    display grid
    grid-template-columns min-content 1fr
    grid-gap 3px
    align-items flex-start

  &__selector
    flex 1
    display flex
    align-items center
    justify-content space-between


