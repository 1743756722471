.annotators
  display grid
  grid-auto-flow column
  grid-column-gap 4px

  &__item
    z-index 1
    width 28px
    height 28px
    border-radius 100%
    position relative

    &:hover
      z-index 10

.userpic-badge
  width 10px
  height 10px
  color #fff
  display flex
  align-items center
  justify-content center
  border-radius 2px
  box-shadow 0 0 0 2px #fff

  &_accepted
    background-color #64BF00

  &_rejected
    background-color #DD0000

  &_fixed
    background-color #FA8C16
