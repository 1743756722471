.sidebar
  width 420px
  background #fff
  margin-top -1em
  padding-top 1em
  position relative

  &::before
    content ""
    position absolute
    top 0
    right 100%
    width 2px
    height 100%
    background linear-gradient(to right, #0000002e, transparent)
    z-index 100

  &__header
    height 32px
    display flex
    padding 0 16px
    align-items center

  &__title
    font-size 18px
    font-weight 500

  &__extra
    margin-right 16px
