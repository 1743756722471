.userpic
  width 28px
  height 28px
  display flex
  font-size 12px
  position relative
  align-items center
  border-radius 50%
  justify-content center
  background rgba(0, 0, 0, 0.08)
  box-shadow 0px 0px 0px 1px rgba(0, 0, 0, 0.1)

  img
    opacity 0
    width 100%
    height 100%
    font-size 12px
    line-height 22px
    object-fit cover
    position absolute
    border-radius 100%

  &__username
    display block
    line-height 22px
    font-weight bold
    font-family Roboto
    opacity 0.6

  &__badge
    position absolute

    &_topLeft
      top 0
      left 0

    &_topRight
      top 0
      right 0

    &_bottomLeft
      bottom 0
      left 0

    &_bottomRight
      bottom 0
      right 0

  &_faded
    background rgba(0, 0, 0, 0.03)
    box-shadow inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)

  &_faded &__username
    opacity 0.2

  &_faded img
    opacity 0.3
