.data-view
  flex 1
  display flex
  overflow hidden
  flex-direction column

  &_loading
    opacity 0.6
    pointer-events none

  &_locked
    pointer-events none

  &__footer
    display flex
    padding 5px 8px
    min-height 36px
    align-items center
    justify-content flex-end
    background-color #fff
    border-top 1px solid rgba(#000, 0.1)

.no-results
  height 100%
  display flex
  flex-direction column
  justify-content center
  align-items center

  &__description
    font-size 16px
    text-align center
    color rgba(#000, 0.6)

.syncInProgress
  height 100%
  display flex
  flex-direction column
  justify-content center
  align-items center
  font-size 16px
  text-align center
  color rgba(#000, 0.6)

  .button
    color rgba(9, 109, 217, 1)
    padding 8px 16px
    border 1px solid rgba(9, 109, 217, 0.16)
    border-radius 4px
    box-shadow none
    font-size 16px
    font-weight 500
    line-height 24px
    letter-spacing 0.15px
  &__text
    margin-bottom 1em