.agreement
  &__score
    &_zero
      color: #000

    &_low
      color: #DD0000

    &_medium
      color: #D98F00

    &_high
      color: #419400
