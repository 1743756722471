.grid-view
  --column-count 4

  &__resize
    width 100%

  &__list
    padding 0 0 16px

  &__cell
    padding: 16px 16px 0 0
    box-sizing border-box

    &:nth-child(var(--column-count))
      padding-right: 0;

    &-header
      padding 5px
      width 100%
      display flex
      background #f9f9f9
      justify-content space-betwee

    &-content
      width 100%
      height 100%
      cursor pointer
      overflow hidden
      position relative
      border-radius 2px
      background none

      img
        object-fit contain

      &::after
        top 0
        left 0
        right 0
        bottom 0
        content ""
        border-radius 2px
        position absolute
        pointer-events none
        box-shadow 0 0 0 1px rgba(0,0,0,0.2) inset

    &_selected &-content
      background-color #eff7ff
      box-shadow 0 0 2px 2px rgba($accent_color, 0.44)

      &::after
        box-shadow 0 0 0 1px rgba(26, 144, 255, 0.6) inset

  .skeletonLoader
    padding 5px