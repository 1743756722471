.radio-group
  --radius 8px
  --height 40px
  --padding 4px
  --font-size 16px
  --button-padding 0 10px
  --button-checked-shadow 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 5px 10px rgba(0, 0, 0, 0.15)

  height var(--height)
  border-radius var(--radius)
  padding var(--padding)
  background linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF
  box-shadow inset 0px 1px 0px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)

  &__buttons
    height calc(var(--height) - calc(var(--padding) * 2))
    display grid
    grid-auto-columns 1fr
    grid-auto-flow column

  &__button
    display flex
    opacity 0.6
    padding var(--button-padding)
    font-weight 500
    position relative
    text-align center
    align-items center
    justify-content center
    font-size var(--font-size)
    border-radius calc(var(--radius) - var(--padding))
    height calc(var(--height) - calc(var(--padding) * 2))
    cursor pointer

    &_checked
      opacity 1
      background-color #fff
      box-shadow: var(--button-checked-shadow)

    &_disabled
      opacity 0.3
      cursor not-allowed

  &__input
    top 0
    left 0
    opacity 0
    width 100%
    height 100%
    position absolute

  &_size
    &_medium
      --height 32px
      --radius 8px
      --font-size 14px

    &_small
      --height 24px
      --radius 4px
      --padding 2px
      --font-size 12px
      --button-padding 0 5px
      --button-checked-shadow 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.15)
  &_newUI
    --button-checked-shadow none
    --button-padding 4px 8px
    box-shadow none
    background rgba(240, 238, 242, 0.08)
    border 1px solid rgba(65, 60, 74, 0.16);
    .radio-group
      &__buttons
        gap 8px
      &__button
        --button-border-width 1px
        opacity 1
        border var(--button-border-width) solid transparent
        height calc(var(--height) - calc(var(--padding) * 2) - calc(var(--button-border-width) * 2))
        cursor pointer
        .radio-group__input
          cursor pointer
        &_checked
          color #096DD9
          background #096DD914
          border-color #096DD9
          cursor default
          .radio-group__input
            cursor default
        &:hover
          background linear-gradient(0deg, rgba(9, 109, 217, 0.05), rgba(9, 109, 217, 0.05)), #FAFAFA

