.resizer
  position: relative;

  &__content
    margin-left -4px
    padding-inline 4px

  &__handle
    top 0
    left 100%
    height 100%
    padding 0 5px
    position absolute
    cursor col-resize
    z-index 100

    &::before
      top 0
      left 50%
      bottom 0
      width 1px
      content ""
      z-index 5
      display block
      background #bdbdbd
      position absolute

    &_quickview::before
      left 0

    &:hover::before,
    &_resizing::before
      top 0
      bottom 0
      background-color $accent_color
      box-shadow 0 0 0 1px $accent_color
