.table-head
  display flex
  width 100%
  height 50px
  z-index 150
  font-weight 500
  position sticky
  overflow visible
  background-color #fff
  min-width fit-content
  font-size 14px
  border-bottom 1px solid #D8D5DD

  &__extra
    flex 1
    display flex
    padding-right 14px
    align-items center
    justify-content flex-end

  &__column-extra
    margin-left 10px
    display flex
    align-items center

  &__draggable
    --translatex 0
    --translatey 0
    transform translate(var(--translatex), var(--translatey))
    cursor move