.filters
  background-color white
  position relative

  &:not(&_sidebar)
    padding-top 10px
    min-width: 400px;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  &_sidebar
    width: 100%;

    .filter-line
      padding-right: 10px;
      padding-left: 10px;
      align-items: stretch;

      .ant-divider
        margin: 0;
        height: 24px;

      ^[0]__field
        width: 100%;

      ^[0]__settings
        flex-direction: column;

      ^[0]__remove
        height: 56px;

      ^[0]__group
        flex: 1;
        padding: 2px 0;
        width: 100%;

  &__actions
    display flex
    margin-top 10px
    padding 0 10px 10px
    justify-content space-between

  &__empty
    padding 0 10px
    font-size 14px
    color #585858

  &__list
    padding 0 10px

    &_withFilters
      display grid
      grid-template-columns 65px min-content min-content 1fr 24px
      grid-gap 3px 4px

  &_sidebar &__list
    &_withFilters
      grid-template-columns 1fr 1fr 24px
      grid-row-gap 5px
      grid-auto-flow row
      align-items center

.filter-data-tag
  margin 1px
  font-size 12px
  padding 3px 5px
  display inline-flex
  align-items center
