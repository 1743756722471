.audio-seeker
  height 5px
  width 100%
  position relative
  border-radius 0 0 4px 4px
  background-color rgba(#000, 0.05)

  &__wrapper
    width 100%
    height 100%
    overflow hidden
    position relative
    border-radius 0 0 4px 4px

    &_video
      border-radius 0 0 4px 0

  &__progress
    height 100%
    z-index 2
    position relative
    background-color rgba($accent_color, 0.8)
    transition background-color 80ms ease

  &::before
    top -5px
    left -5px
    right -5px
    bottom -5px
    content ''
    display block
    position absolute

  &::hover &__progress
    background-color $accent_color

  &__buffer
    top 0
    left 0
    z-index 1
    height 100%
    position absolute
    background-color rgba(#000, 0.05)
