.badge
  height 24px
  font-size 16px
  border-radius 30px
  display inline-flex
  align-items center
  justify-content center
  padding 0 7px
  color #fff
  background-color $accent_color

  &_size_small
    height 16px
    font-size 12px
    padding 0 5px

  &_size_medium
    height 24px
    font-size 16px
    padding 0 7px

  &_size_large
    height 32px
    font-size 18px
    padding 0 10px
