@import "../../styles/waiting.styl"

.label-view
  height 100%
  width 100%
  display flex
  flex-direction column

  &__waiting
    top 50%
    left 50%
    width 150px
    height 10px
    z-index 100
    position absolute
    border-radius 4px
    transform translate(-50%, -50%)
    box-shadow 0 5px 20px rgba(0, 0, 0, 0.2)

    waiting($accent_color)

  &__header
    flex none
    display flex
    min-height 56px
    padding 0 1em
    align-items center
    justify-content space-between

  &__table
    z-index 2
    display flex
    height 100%
    flex 200px 0 0
    position relative
    flex-direction column

  &__content
    flex 1
    display flex
    height calc(100% - 46px)

  &__dataview
    flex 1
    width 100%
    height 100%
    display flex
    min-width 200px
    box-shadow 0 0 0 1px #ccc

  &__lsf-wrapper
    flex 1
    z-index 1
    min-width 0
    display flex
    position relative
    box-sizing border-box
    flex-direction column

    &_mode
      &_explorer
        margin-left 1px
        margin-top: -1px;
        border-top: 1px solid rgba(#000, 0.15);

  &__lsf-container
    width 100%
    height 100%
    display flex
    flex-direction column
    overflow-y auto

    ~/_loading &
      opacity 0.2
      pointer-events none

    & > div
      --main-bg-color #FAFAFA

      flex 1
      height auto

    &_outliner > div
      height 100%

  &__lsf-toolbar
    height 50px

  &__lsf-content
    flex 1
    overflow auto
    box-sizing border-box
    padding-bottom 50px
